/** @jsxImportSource @emotion/react */
import { useParams } from 'react-router-dom';

import { Typography } from 'antd';

import { preset } from 'styles';
import { Container } from 'components';
import AccountMenu from './components/AccountMenu';
import General from './General';
import Security from './Security';

const { Title } = Typography;

const Account = () => {
  const { page } = useParams();

  const currentMenu = page || 'general';

  const renderContent = () => {
    switch (currentMenu) {
      case 'security':
        return <Security />;
      default:
        return <General />;
    }
  };

  return (
    <Container paddingY={3}>
      <div
        css={{
          backgroundColor: '#fff',
          paddingTop: preset.spacing(2),
          paddingBottom: preset.spacing(2),
          display: 'flex',
        }}
      >
        <div
          css={{
            width: 240,
            borderRight: preset.border,
          }}
        >
          <AccountMenu currentMenu={currentMenu} />
        </div>
        <div
          css={{
            flex: 1,
            padding: `${preset.spacing(1)}px ${preset.spacing(5)}px`,
          }}
        >
          <Title
            level={4}
            css={{
              textTransform: 'capitalize',
            }}
          >
            {currentMenu}
          </Title>
          <div
            css={{
              paddingTop: preset.spacing(1),
              minHeight: 400,
            }}
          >
            {renderContent()}
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Account;
