/** @jsxImportSource @emotion/react */
import { useState } from 'react';

// import Ant design
import { Button, Alert, message, Popconfirm, Space } from 'antd';
import { CheckCircleFilled } from '@ant-design/icons';

import { preset } from 'styles';
import { auth } from 'firebase-export';

const microSoftProvider = new auth.OAuthProvider('microsoft.com');

const MicrosoftSignInConfig = ({
  disabled = false,
  provider,
  refreshUserData,
}) => {
  const [loading, setLoading] = useState(false);

  const linkToMicrosoftAccount = async () => {
    try {
      setLoading(true);
      const { currentUser } = await auth();
      microSoftProvider.setCustomParameters({
        tenant: 'lgsherlock.onmicrosoft.com',
        login_hint: currentUser.email,
      });
      await currentUser.linkWithRedirect(microSoftProvider);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      message.error(err.message);
    }
  };

  const unLinkToMicrosoftAccount = async () => {
    try {
      setLoading(true);
      const { currentUser } = await auth();
      await currentUser.unlink('microsoft.com');
      refreshUserData();
      message.success('Microsoft is unlinked');
      setLoading(false);
    } catch (err) {
      setLoading(false);
      message.error(err.message);
    }
  };

  return (
    <>
      {disabled && (
        <Alert
          showIcon
          type="info"
          message="Microsoft SSO is disabled when two-factor authentication is on"
          css={{ marginBottom: preset.spacing(3) }}
        />
      )}

      <Space size="middle">
        {provider && provider.email && (
          <Space>
            <CheckCircleFilled />
            {provider.email}
          </Space>
        )}
        <Popconfirm
          disabled={!provider}
          title="Are you sure you want to unlink Microsoft?"
          okText="Unlink"
          okType="danger"
          onConfirm={unLinkToMicrosoftAccount}
        >
          <Button
            disabled={disabled}
            loading={loading}
            {...(provider
              ? {
                  danger: true,
                }
              : {
                  type: 'primary',
                  onClick: linkToMicrosoftAccount,
                })}
          >
            {provider ? 'Unlink Microsoft' : 'Link Microsoft'}
          </Button>
        </Popconfirm>
      </Space>
    </>
  );
};

export default MicrosoftSignInConfig;
