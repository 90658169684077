/** @jsxImportSource @emotion/react */
import { useState } from 'react';
import firebase from 'firebase/app';

import { Form, Input, Button, message } from 'antd';

import { useRecaptchaVerifier } from 'hooks';
import { SMSVerifyForm } from 'components';

const PhoneSetupForm = ({ onSetupFinish }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [verificationId, setVerificationId] = useState();

  const appVerifier = useRecaptchaVerifier(
    verificationId
      ? 'verification-code-submit-button'
      : 'phone-number-submit-button'
  );

  const onPhoneNumberConfirm = async (phoneNumber) => {
    setIsLoading(true);
    try {
      const { currentUser } = await firebase.auth();
      // Get a multi-factor session for the user
      const multiFactorSession = await currentUser.multiFactor.getSession();

      const phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
      const res = await phoneAuthProvider.verifyPhoneNumber(
        {
          phoneNumber,
          session: multiFactorSession,
        },
        appVerifier
      );

      setVerificationId(res);
    } catch (error) {
      message.error(error.message);
    }
    setIsLoading(false);
  };

  const enrollSMSMFA = async (multiFactorAssertion) => {
    const { currentUser } = await firebase.auth();
    // Complete enrollment.
    await currentUser.multiFactor.enroll(multiFactorAssertion);

    message.success('SMS MFA Set Up');
    onSetupFinish();
  };

  return verificationId ? (
    <SMSVerifyForm
      verificationId={verificationId}
      onVerifySuccess={enrollSMSMFA}
    />
  ) : (
    <Form
      layout="vertical"
      onFinish={(values) => onPhoneNumberConfirm(values.phone)}
    >
      <Form.Item
        name="phone"
        label="Phone number"
        rules={[{ required: true, message: 'Please enter your phone number.' }]}
      >
        <Input placeholder="e.g. +61420555123" />
      </Form.Item>
      <Form.Item>
        <Button
          id="phone-number-submit-button"
          block
          loading={isLoading}
          type="primary"
          htmlType="submit"
        >
          Next
        </Button>
      </Form.Item>
    </Form>
  );
};

export default PhoneSetupForm;
