/** @jsxImportSource @emotion/react */
import React from 'react';
import { useHistory } from 'react-router-dom';
import { PageHeader } from 'antd';

import { Container, NewUserForm } from 'components';

export const CreateUserInSuperAdmin: React.FC = () => {
  const history = useHistory();

  return (
    <div>
      <PageHeader
        ghost={false}
        title="Create User"
        onBack={() => history.push('/superadmin/users')}
      />

      <Container paddingY={3}>
        <NewUserForm />
      </Container>
    </div>
  );
};
