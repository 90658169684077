/** @jsxImportSource @emotion/react */
import React, {
  useState,
  useEffect,
  useMemo,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { Link } from 'react-router-dom';
import { Table, Row, Col, Button, Input, message, TableColumnType } from 'antd';
import capitalize from 'lodash/capitalize';
import moment from 'moment-timezone';
import { useUserInfoStore, ProductRole } from '@eliiza-thea/auth-wrapper';

import { preset } from 'styles';
import { getOrgUsers, getAPIErrMsg } from 'api';
import { OrgUserInfo } from 'types';
import { useIsSuperAdminRoute } from 'hooks';
import { getUserDisplayName } from 'utils';

type Ref = { setUsers: React.Dispatch<React.SetStateAction<OrgUserInfo[]>> };
type Props = {
  orgSlug?: string;
  renderActions?: (user: OrgUserInfo) => React.ReactNode;
};

export const OrgUsersTable = forwardRef<Ref, Props>(
  ({ orgSlug, renderActions }, ref) => {
    const [users, setUsers] = useState<OrgUserInfo[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [searchText, setSearchText] = useState<string>('');

    const isSuperAdminRoute = useIsSuperAdminRoute();

    const { getUserInfo } = useUserInfoStore();

    useEffect(() => {
      const fetchOrgUsers = async () => {
        if (orgSlug) {
          setIsLoading(true);
          try {
            const orgUsers = await getOrgUsers(orgSlug);
            const rawOrgUsersInfo = await Promise.all(
              orgUsers.map(async (orgUser) => {
                const firestoreUser = await getUserInfo(orgUser.id);
                return firestoreUser ? { ...firestoreUser, ...orgUser } : null;
              })
            );
            const filteredOrgUsersInfo = rawOrgUsersInfo.filter(
              (user) => user
            ) as OrgUserInfo[];
            setUsers(filteredOrgUsersInfo);
          } catch (error) {
            message.error(getAPIErrMsg(error));
          }
          setIsLoading(false);
        }
      };
      fetchOrgUsers();
    }, [orgSlug, getUserInfo]);

    useImperativeHandle(ref, () => ({ setUsers }));

    const filteredUsers = useMemo(() => {
      if (!searchText) return users;
      return users.filter((user) => {
        return !![user.email, user.firstName, user.lastName].find(
          (userString) =>
            userString
              ? userString.toLowerCase().includes(searchText.toLowerCase())
              : false
        );
      });
    }, [searchText, users]);

    return (
      <>
        <Row justify="space-between" css={{ marginBottom: preset.spacing(3) }}>
          <Col span={10}>
            <Input.Search
              allowClear
              placeholder="Search"
              onSearch={setSearchText}
            />
          </Col>
          <Col>
            <Button type="primary">
              <Link
                to={
                  isSuperAdminRoute
                    ? `/superadmin/users/create${
                        orgSlug ? `?orgSlug=${orgSlug}` : ''
                      }`
                    : '/users/create'
                }
              >
                Create User
              </Link>
            </Button>
          </Col>
        </Row>
        <Table
          bordered
          loading={isLoading}
          columns={[
            {
              title: 'Email',
              dataIndex: 'email',
              render: (email, row) => (
                <Link
                  to={`${isSuperAdminRoute ? '/superadmin' : ''}/users/${
                    row.id
                  }`}
                >
                  {email}
                </Link>
              ),
            },
            {
              title: 'Name',
              key: 'name',
              render: (val, row) => getUserDisplayName(row),
            },
            {
              title: 'Role',
              children: [
                {
                  title: 'Organisation',
                  dataIndex: 'orgRole',
                  render: (role) => capitalize(role),
                },
                ...['insight', 'data', 'annotator'].map((productKey) => ({
                  title: capitalize(productKey),
                  dataIndex: productKey,
                  render: (roleObj?: { productRole: ProductRole }) =>
                    roleObj?.productRole && capitalize(roleObj.productRole),
                })),
              ],
            },
            {
              title: 'Created At',
              dataIndex: 'createdAt',
              render: (createdAt: OrgUserInfo['createdAt']) =>
                createdAt && moment(createdAt).format('DD/MM/YYYY'),
            },
            ...(renderActions
              ? [
                  {
                    title: 'Action',
                    key: 'action',
                    render: (val: any, row: OrgUserInfo) => renderActions(row),
                  } as TableColumnType<OrgUserInfo>,
                ]
              : []),
          ]}
          rowKey="id"
          dataSource={filteredUsers}
          locale={{ emptyText: 'No User Found' }}
          pagination={{
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`,
          }}
        />
      </>
    );
  }
);
