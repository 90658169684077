/** @jsxImportSource @emotion/react */
import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  PageHeader,
  message,
  Skeleton,
  Descriptions,
  Card,
  Form,
  Button,
  Space,
  Popconfirm,
  Modal,
} from 'antd';
import moment from 'moment-timezone';
import { UploadFile } from 'antd/es/upload/interface';
import {
  Organisation,
  useUserInfoStore,
  OrgRole,
  ProductKey,
  ProductRole,
} from '@eliiza-thea/auth-wrapper';
import pickBy from 'lodash/pickBy';

import {
  getOrg,
  updateOrg,
  updateOrgUserRoles,
  removeUserFromOrg,
  getAPIErrMsg,
} from 'api';
import {
  Container,
  TimezoneSelect,
  ImageUploadFormItem,
  OrgUsersTable,
  UserRoleFormItems,
} from 'components';
import { preset } from 'styles';
import { app } from 'firebase-export';
import { OrgUserInfo, UserRoles, FirebaseStorageError } from 'types';
import { getUserDisplayName, transformUserRoles } from 'utils';

import { uploadOrgLogo } from 'storage';

export const EditOrganisation: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [org, setOrg] = useState<Organisation | null>(null);
  const [isSavingOrg, setIsSavingOrg] = useState<boolean>(false);
  const [editingUser, setEditingUser] = useState<OrgUserInfo | null>(null);
  const [isSavingUser, setIsSavingUser] = useState<boolean>(false);

  const { orgSlug } = useParams<{ orgSlug?: string }>();
  const history = useHistory();

  const [settingsForm] = Form.useForm();
  const [userRoleForm] = Form.useForm();

  const { getUserInfo } = useUserInfoStore();

  const orgUsersTableRef = useRef<{
    setUsers: React.Dispatch<React.SetStateAction<OrgUserInfo[]>>;
  } | null>(null);

  useEffect(() => {
    const fetchOrg = async () => {
      if (orgSlug) {
        setIsLoading(true);
        try {
          const orgData = await getOrg(orgSlug);
          settingsForm.setFieldsValue({ timezone: orgData?.timezone });
          if (orgData?.logo) {
            // Fetch org logo
            const storageRef = app().storage(`gs://otso-data-${orgSlug}`).ref();
            const logoRef = storageRef.child(`system/${orgData.logo}`);
            try {
              const url: string = await logoRef.getDownloadURL();
              settingsForm.setFieldsValue({ logoFile: [{ url }] });
            } catch {
              settingsForm.setFieldsValue({ logoFile: [] });
            }
          }
          setOrg(orgData);
        } catch (error) {
          message.error(getAPIErrMsg(error));
        }
        setIsLoading(false);
      }
    };
    fetchOrg();
  }, [orgSlug, settingsForm, getUserInfo]);

  const onSettingsSave = async (values: {
    logoFile?: UploadFile[];
    timezone?: string;
  }) => {
    const { logoFile, timezone } = values;

    let newLogoPath: string | null = null;

    if (orgSlug && Array.isArray(logoFile) && logoFile.length > 0) {
      const imageFile = logoFile[0];
      if ('originFileObj' in imageFile && imageFile.originFileObj) {
        setIsSavingOrg(true);
        try {
          newLogoPath = await uploadOrgLogo(imageFile.originFileObj, orgSlug);
        } catch (error) {
          message.error(
            (error as FirebaseStorageError)?.message || 'Upload Fail'
          );
        }
      } else if (imageFile.url && org?.logo) {
        newLogoPath = org.logo;
      }
    }

    if (org?.id) {
      setIsSavingOrg(true);
      try {
        await updateOrg(org.id, {
          logo: newLogoPath,
          ...(timezone ? { timezone } : {}),
        });
        message.success('Organisation Settings Updated');
      } catch (error) {
        message.error(getAPIErrMsg(error));
      }
      setIsSavingOrg(false);
    }
  };

  const onEditOrgUserRole = (selectedUser: OrgUserInfo) => {
    userRoleForm.setFieldsValue({
      orgRole: selectedUser.orgRole,
      productRole: {
        insight: selectedUser.insight?.productRole,
        data: selectedUser.data?.productRole,
        annotator: selectedUser.annotator?.productRole,
      },
    });
    setEditingUser(selectedUser);
  };

  const onEditOrgUserRoleCancel = () => {
    userRoleForm.resetFields();
    setEditingUser(null);
  };

  const onUserRolesSave = async () => {
    if (editingUser && orgSlug) {
      setIsSavingUser(true);
      try {
        const values: {
          orgRole: OrgRole;
          productRole: { [key in ProductKey]?: ProductRole | '' };
        } = await userRoleForm.validateFields();
        const formattedRoles: UserRoles = {
          orgRole: values.orgRole,
          productRole: pickBy(values.productRole, (val) => !!val),
        };
        await updateOrgUserRoles(editingUser.id, formattedRoles, orgSlug);
        // Update OrgUsersTable state
        orgUsersTableRef.current?.setUsers((prevUsers) =>
          prevUsers.map((user) => {
            if (user.id === editingUser.id) {
              const { orgRole, insight, data, annotator, ...restUser } = user;
              return { ...restUser, ...transformUserRoles(formattedRoles) };
            }
            return user;
          })
        );
        message.success('User Roles Updated');
        onEditOrgUserRoleCancel();
      } catch (error) {
        // No error alert if it's antd form error
        if (!(error as any).errorFields) {
          message.error(getAPIErrMsg(error));
        }
      }
      setIsSavingUser(false);
    }
  };

  const onRemoveOrgUser = async (userId: string) => {
    if (orgSlug) {
      try {
        await removeUserFromOrg(userId, orgSlug);
        // Update OrgUsersTable state
        orgUsersTableRef.current?.setUsers((prevUsers) =>
          prevUsers.filter((user) => user.id !== userId)
        );
        message.success('User Removed');
      } catch (error) {
        message.error(getAPIErrMsg(error));
      }
    }
  };

  return (
    <div>
      <PageHeader
        ghost={false}
        title={org?.name}
        onBack={() => history.push('/superadmin/organisations')}
      >
        <Skeleton loading={isLoading}>
          <Descriptions>
            <Descriptions.Item label="Name">{org?.name}</Descriptions.Item>
            <Descriptions.Item label="Created At">
              {org?.createdAt &&
                moment(org.createdAt).format('DD/MM/YYYY HH:mm:ss')}
            </Descriptions.Item>
          </Descriptions>
        </Skeleton>
      </PageHeader>

      <Container paddingY={3}>
        <Card
          title="Settings"
          bordered={false}
          css={{ marginBottom: preset.spacing(3) }}
        >
          <Form
            form={settingsForm}
            onFinish={onSettingsSave}
            layout="vertical"
            css={{
              maxWidth: preset.centerFormWidth,
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          >
            <ImageUploadFormItem label="Logo" name="logoFile" />

            <Form.Item
              name="timezone"
              label="Time zone"
              tooltip="New users created in this organisation will have this time zone"
            >
              <TimezoneSelect />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" loading={isSavingOrg}>
                Save
              </Button>
            </Form.Item>
          </Form>
        </Card>

        <Card title="Users">
          <OrgUsersTable
            ref={orgUsersTableRef}
            orgSlug={org?.id}
            renderActions={(selectedUser) => (
              <Space css={{ marginLeft: -preset.spacing(1) }}>
                <Button
                  size="small"
                  type="link"
                  onClick={() => onEditOrgUserRole(selectedUser)}
                >
                  Edit Role
                </Button>
                <Popconfirm
                  title={`Are you sure you want to remove ${getUserDisplayName(
                    selectedUser
                  )} from ${org?.name}?`}
                  okText="Remove"
                  okButtonProps={{ danger: true }}
                  onConfirm={() => onRemoveOrgUser(selectedUser.id)}
                >
                  <Button size="small" type="link" danger>
                    Remove
                  </Button>
                </Popconfirm>
              </Space>
            )}
          />
        </Card>
      </Container>

      <Modal
        title="Edit Role"
        width={preset.modalWidth.md}
        visible={!!editingUser}
        onCancel={onEditOrgUserRoleCancel}
        okText="Save"
        onOk={onUserRolesSave}
        okButtonProps={{ loading: isSavingUser }}
      >
        <Form layout="vertical" form={userRoleForm}>
          <UserRoleFormItems form={userRoleForm} />
        </Form>
      </Modal>
    </div>
  );
};
