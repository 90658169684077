import firebase from 'firebase/app';
import { Space } from 'antd';
import {
  WindowsOutlined,
  MailOutlined,
  GoogleOutlined,
} from '@ant-design/icons';

type ProviderIconProps = { providerId: string };

const ProviderIcon: React.FC<ProviderIconProps> = ({ providerId }) => {
  switch (providerId) {
    case 'microsoft.com':
      return <WindowsOutlined />;
    case 'google.com':
      return <GoogleOutlined />;
    case 'password':
      return <MailOutlined />;
    default:
      return null;
  }
};

type Props = { providers: firebase.User['providerData'] };

export const UserProviderIcons: React.FC<Props> = ({ providers }) => {
  return (
    <Space>
      {providers.map(
        (provider) =>
          provider && (
            <ProviderIcon
              key={provider.providerId}
              providerId={provider.providerId}
            />
          )
      )}
    </Space>
  );
};
