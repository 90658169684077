import { useState, useEffect } from 'react';
import firebase from 'firebase/app';

import { auth } from 'firebase-export';

export const useRecaptchaVerifier = (
  buttonId: string
): firebase.auth.RecaptchaVerifier | null => {
  const [reCAPTCH, setReCAPTCH] =
    useState<firebase.auth.RecaptchaVerifier | null>(null);

  useEffect(() => {
    if (buttonId) {
      const targetBtn = document.getElementById(buttonId);
      if (targetBtn) {
        try {
          // Init reCAPTCHA verifier to avoid spam
          const appVerifier = new auth.RecaptchaVerifier(buttonId, {
            size: 'invisible',
          });
          setReCAPTCH(appVerifier);
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log(error);
        }
      }
    }
  }, [buttonId]);

  return reCAPTCH;
};
