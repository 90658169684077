/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import {
  PageHeader,
  message,
  Descriptions,
  Skeleton,
  Tag,
  Form,
  Button,
  Dropdown,
  Menu,
  Modal,
  Space,
  Card,
} from 'antd';
import { EllipsisOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import moment from 'moment-timezone';
import capitalize from 'lodash/capitalize';
import pickBy from 'lodash/pickBy';
import { OrgRole, ProductRole, ProductKey } from '@eliiza-thea/auth-wrapper';

import {
  getAdminViewUser,
  removeUserFromCurrentOrg,
  updateCurrentOrgUserRoles,
  getAPIErrMsg,
} from 'api';
import { AdminViewUser, UserRoles } from 'types';
import { UserProviderIcons, Container, UserRoleFormItems } from 'components';
import { preset } from 'styles';
import { getUserDisplayName } from 'utils';

type MenuKey = 'remove';

export const EditUser: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [user, setUser] = useState<AdminViewUser | null>(null);
  const [isSavingRoles, setIsSavingRoles] = useState<boolean>(false);

  const { userId } = useParams<{ userId?: string }>();
  const history = useHistory();

  const [form] = Form.useForm();

  useEffect(() => {
    const fetchUser = async () => {
      if (userId) {
        setIsLoading(true);
        try {
          const userData = await getAdminViewUser(userId);
          form.setFieldsValue({
            orgRole: userData.orgRole,
            productRole:
              userData.orgRole === 'admin'
                ? { insight: 'admin', data: 'admin', annotator: 'admin' }
                : userData.productRole,
          });
          setUser(userData);
        } catch (error) {
          message.error(getAPIErrMsg(error));
        }
        setIsLoading(false);
      }
    };
    fetchUser();
  }, [userId, form]);

  const userDisplayName = getUserDisplayName(user);

  const removeUserFromOrg = async () => {
    if (userId) {
      try {
        await removeUserFromCurrentOrg(userId);
        message.success('User Removed');
        history.push('/users');
      } catch (error) {
        message.error(getAPIErrMsg(error));
      }
    }
  };

  const onUserRolesSave = async (values: {
    orgRole: OrgRole;
    productRole: { [key in ProductKey]?: ProductRole | '' };
  }) => {
    if (userId) {
      const formattedRoles: UserRoles = {
        orgRole: values.orgRole,
        productRole: pickBy(values.productRole, (val) => !!val),
      };
      setIsSavingRoles(true);
      try {
        await updateCurrentOrgUserRoles(userId, formattedRoles);
        message.success('User Roles Updated');
      } catch (error) {
        message.error(getAPIErrMsg(error));
      }
      setIsSavingRoles(false);
    }
  };

  const onMenuClick = (key: MenuKey) => {
    switch (key) {
      case 'remove':
        Modal.confirm({
          title: `Are you sure you want to remove ${userDisplayName} from this organisation?`,
          icon: <ExclamationCircleOutlined />,
          okText: 'Remove',
          okButtonProps: { danger: true },
          onOk: removeUserFromOrg,
        });
        break;
      default:
        break;
    }
  };

  const isMFAEnabled = !!user?.multiFactor?.enrolledFactors?.length;

  return (
    <div>
      <PageHeader
        ghost={false}
        title={userDisplayName}
        onBack={() => history.push('/users')}
        extra={
          <Dropdown
            trigger={['click']}
            overlay={
              <Menu onClick={({ key }) => onMenuClick(key as MenuKey)}>
                <Menu.Item key="remove" danger>
                  Remove User from Organisation
                </Menu.Item>
              </Menu>
            }
          >
            <Button icon={<EllipsisOutlined />} />
          </Dropdown>
        }
      >
        <Skeleton loading={isLoading}>
          <Descriptions>
            <Descriptions.Item label="Name">
              {userDisplayName}
            </Descriptions.Item>
            <Descriptions.Item label="Email">{user?.email}</Descriptions.Item>
            <Descriptions.Item label="Organisation Role">
              {capitalize(user?.orgRole)}
            </Descriptions.Item>
            <Descriptions.Item label="Created At">
              {user?.createdAt &&
                moment(user.createdAt).format('DD/MM/YYYY HH:mm:ss')}
            </Descriptions.Item>
            <Descriptions.Item label="Last Login">
              {user?.lastSignInTime &&
                moment(user.lastSignInTime).format('DD/MM/YYYY HH:mm:ss')}
            </Descriptions.Item>
            <Descriptions.Item label="Providers">
              <UserProviderIcons providers={user?.providerData || []} />
            </Descriptions.Item>
            <Descriptions.Item label="MFA">
              <Tag color={isMFAEnabled ? 'success' : 'default'}>
                {isMFAEnabled ? 'Active' : 'Disabled'}
              </Tag>
            </Descriptions.Item>
            <Descriptions.Item label="Account Status">
              <Tag color={user?.disabled ? 'default' : 'success'}>
                {user?.disabled ? 'Disabled' : 'Active'}
              </Tag>
            </Descriptions.Item>
          </Descriptions>
        </Skeleton>
      </PageHeader>

      <Container paddingY={3}>
        <Form layout="vertical" form={form} onFinish={onUserRolesSave}>
          <Card
            title="Roles"
            loading={isLoading}
            bordered={false}
            css={{ marginBottom: preset.spacing(3) }}
          >
            <UserRoleFormItems form={form} />
          </Card>

          {user && (
            <Form.Item>
              <Space size="middle">
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={isSavingRoles}
                >
                  Save
                </Button>
                <Button>
                  <Link to="/users">Cancel</Link>
                </Button>
              </Space>
            </Form.Item>
          )}
        </Form>
      </Container>
    </div>
  );
};
