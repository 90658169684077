/** @jsxImportSource @emotion/react */
import { useState } from 'react';

// import Ant design
import { Button, Alert, message, Popconfirm, Space } from 'antd';
import { CheckCircleFilled } from '@ant-design/icons';

import { preset, colors } from 'styles';
import { auth } from 'firebase-export';

const googleProvider = new auth.GoogleAuthProvider();

const GoogleSignInConfig = ({
  disabled = false,
  provider,
  refreshUserData,
}) => {
  const [loading, setLoading] = useState(false);

  const linkToGoogleAccount = async () => {
    try {
      setLoading(true);
      const { currentUser } = await auth();
      await currentUser.linkWithRedirect(googleProvider);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      message.error(err.message);
    }
  };

  const unLinkToGoogleAccount = async () => {
    try {
      setLoading(true);
      const { currentUser } = await auth();
      await currentUser.unlink('google.com');
      refreshUserData();
      message.success('Google Sign-In is unlinked');
      setLoading(false);
    } catch (err) {
      setLoading(false);
      message.error(err.message);
    }
  };

  return (
    <>
      {disabled && (
        <Alert
          showIcon
          type="info"
          message="Google Sign-In is disabled when two-factor authentication is on"
          css={{ marginBottom: preset.spacing(3) }}
        />
      )}

      <Space size="middle">
        {provider && provider.email && (
          <Space>
            <CheckCircleFilled css={{ color: colors.green }} />
            {provider.email}
          </Space>
        )}
        <Popconfirm
          disabled={!provider}
          title="Are you sure you want to unlink Google Sign-In?"
          okText="Unlink"
          okType="danger"
          onConfirm={unLinkToGoogleAccount}
        >
          <Button
            disabled={disabled}
            loading={loading}
            {...(provider
              ? {
                  danger: true,
                }
              : {
                  type: 'primary',
                  onClick: linkToGoogleAccount,
                })}
          >
            {provider ? 'Unlink Google' : 'Link Google'}
          </Button>
        </Popconfirm>
      </Space>
    </>
  );
};

export default GoogleSignInConfig;
