/** @jsxImportSource @emotion/react */

// Import libraries
import { Link } from 'react-router-dom';

// Import Ant Design components
import {
    Typography, Button, Divider,
} from 'antd';

// Import constants
import { categories } from '../../constants/products';

// Import stylesheets
import styles from './styles';

// Import Additional Ant Design components
const { Title } = Typography;

const Dashboard = () => {
    return (
        <div style={styles.dashboardWrapper}>
            {/* Each category */}
            {categories.map(category => (
                <div key={category.name} style={styles.productCategory}>
                    <Title level={5}>{category.name}</Title>
                    <Divider style={styles.titleDivider} />

                    {/* Each category product */}
                    {category.products.map(product => (
                        <Link key={product.name} to={{ pathname: product.host }} target="_blank">
                            <Button type="primary" size="large" icon={product.icon}>
                                {product.name}
                            </Button>
                        </Link>
                    ))}
                </div>
            ))}
        </div>
    );
};

export default Dashboard;
