export const spacing = (factor: number) => 8 * factor;

export const boxShadow = '0 1px 4px 0 rgb(0 21 41 / 12%)';
export const border = 'solid 1px #e8e8e8';
export const transition = 'all 0.15s';

export const headerHeight = spacing(6);
export const containerWidth = 1440;
export const centerFormWidth = 480;

export const modalWidth = { md: 800, lg: 1200 };

export const breakpoints = {
  lg: '@media (min-width: 1440px)',
};

export const content = {
  width: '50%',
  minWidth: '900px',
  margin: '75px auto 15px',
};

export const button = {
  minWidth: '100px',
  borderRadius: '4px',
};

export const input = {
  borderRadius: '4px',
};

export const card = {
  borderRadius: '8px',
};

export const cardBody = {
  padding: '48px 40px 36px',
};
