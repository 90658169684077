// Import libraries
import ReactDOM from 'react-dom';
import { AuthWrapper, initAuth } from '@eliiza-thea/auth-wrapper';

// Import Ant Design components
import enGB from 'antd/lib/locale/en_GB';
import { ConfigProvider } from 'antd';

import { Routes } from 'routes';
import { AppLayout } from 'components';

// Import antd styles
import 'antd/dist/antd.less';

// Prepare Firebase configurations for different environments
const firebaseConfigs = {
  dev: {
    apiKey: 'AIzaSyAbmTEKZ_ML6RuppTW7lFcGWXqLZ6B_gzs',
    authDomain: 'otso-dev.firebaseapp.com',
    projectId: 'otso-dev',
  },
  app: {
    apiKey: 'AIzaSyD-dSoAehSvmkbOou8XhfTHx3HZD2ohkCA',
    authDomain: 'otso-production-app.firebaseapp.com',
    projectId: 'otso-production-app',
  },
  dpcsa: {
    apiKey: 'AIzaSyCuUluMkTdF7RKbP-JcE-2ydFMcMQ5ao6M',
    authDomain: 'sa-gov.firebaseapp.com',
    projectId: 'sa-gov',
  },
};

/**
 * Retrieve Firebase configurations based on the current environment.
 * @returns an object containing Firebase app configurations
 */
const getFirebaseConfigs = () =>
  firebaseConfigs[process.env.REACT_APP_AUTH_ENV];

initAuth({
  firebaseConfig: getFirebaseConfigs(),
  authAPIHost: process.env.REACT_APP_AUTH_API_URL,
  authHost: process.env.REACT_APP_AUTH_CLIENT_URL,
});

const App = () => {
  return (
    <AuthWrapper logo="/logo/otso_mark-1.png" layout={false}>
      <AppLayout>
        <ConfigProvider locale={enGB}>
          <Routes />
        </ConfigProvider>
      </AppLayout>
    </AuthWrapper>
  );
};

ReactDOM.render(<App />, document.getElementById('root'));
