/** @jsxImportSource @emotion/react */
import React, { useMemo } from 'react';
import { Layout, Row, Col, Menu } from 'antd';
import { Link } from 'react-router-dom';
import {
  Logo,
  OrgSelect,
  NavMenu,
  useAuth,
  UserMenu,
  logout,
} from '@eliiza-thea/auth-wrapper';

import { Container } from 'components';
import { preset, colors } from 'styles';
import { useIsSuperAdminRoute } from 'hooks';
import { getGlobals } from 'modules';

const { doNotShowDashboard } = getGlobals();

type Props = { children: React.ReactNode };

type UserMenuKey = 'superadmin' | 'account' | 'logout';

const USER_MENU_LIST = [
  ...(!doNotShowDashboard ? [{ title: 'Dashboard', path: '/dashboard' }] : []),
  { title: 'Account', path: '/account' },
];

const ADMIN_MENU_LIST = [
  ...(!doNotShowDashboard ? [{ title: 'Dashboard', path: '/dashboard' }] : []),
  { title: 'Users', path: '/users' },
  { title: 'Account', path: '/account' },
];

const SUPER_ADMIN_MENU_LIST = [
  { title: 'Organisations', path: '/superadmin/organisations' },
  { title: 'Users', path: '/superadmin/users' },
];

export const AppLayout: React.FC<Props> = ({ children }) => {
  const { user } = useAuth();

  const isSuperAdminRoute = useIsSuperAdminRoute();

  const onMenuClick = (key: UserMenuKey) => {
    switch (key) {
      case 'logout':
        logout();
        break;
      default:
        break;
    }
  };

  const navMenuRoutes = useMemo(() => {
    return isSuperAdminRoute
      ? SUPER_ADMIN_MENU_LIST
      : user?.orgRole === 'admin'
      ? ADMIN_MENU_LIST
      : USER_MENU_LIST;
  }, [user?.orgRole, isSuperAdminRoute]);

  return (
    <Layout css={{ minHeight: '100vh' }}>
      <Layout.Header
        css={{
          position: 'relative',
          zIndex: 1,
          backgroundColor: colors.white,
          boxShadow: preset.boxShadow,
          height: preset.headerHeight,
          lineHeight: `${preset.headerHeight}px`,
          padding: 0,
        }}
      >
        <Container>
          <Row
            gutter={preset.spacing(2)}
            wrap={false}
            style={{ height: '100%' }}
          >
            <Col>
              <Logo />
            </Col>
            <Col flex="auto">
              <NavMenu routes={navMenuRoutes} />
            </Col>
            <Col flex="240px">{!isSuperAdminRoute && <OrgSelect />}</Col>
            <Col>
              <UserMenu
                menu={
                  <Menu
                    onClick={({ key }) => onMenuClick(key as UserMenuKey)}
                    css={{ minWidth: 160 }}
                  >
                    <Menu.ItemGroup title="Account">
                      {user?.isSuper && (
                        <Menu.Item key="superadmin">
                          <Link to="/superadmin">Super Admin</Link>
                        </Menu.Item>
                      )}
                      <Menu.Item key="account">
                        <Link to="/account">My Account</Link>
                      </Menu.Item>
                    </Menu.ItemGroup>
                    <Menu.Divider />
                    <Menu.Item danger key="logout">
                      Sign Out
                    </Menu.Item>
                  </Menu>
                }
              />
            </Col>
          </Row>
        </Container>
      </Layout.Header>
      <Layout.Content>
        <div
          css={{
            '.ant-page-header': {
              paddingLeft: 0,
              paddingRight: 0,
              '.ant-page-header-heading, .ant-page-header-content': {
                maxWidth: preset.containerWidth,
                marginLeft: 'auto',
                marginRight: 'auto',
                paddingLeft: preset.spacing(3),
                paddingRight: preset.spacing(3),
                [preset.breakpoints.lg]: {
                  paddingLeft: preset.spacing(5),
                  paddingRight: preset.spacing(5),
                },
              },
              '.ant-page-header-heading': {
                '.ant-page-header-heading-title': {
                  minHeight: preset.spacing(4),
                },
                '.ant-page-header-heading-extra': {
                  display: 'inline-flex',
                },
              },
            },
          }}
        >
          {children}
        </div>
      </Layout.Content>
    </Layout>
  );
};
