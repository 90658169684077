/** @jsxImportSource @emotion/react */
import { useState } from 'react';

import { Form, Input, Button, message } from 'antd';

import { SMSVerifyForm } from 'components';
import { useRecaptchaVerifier } from 'hooks';
import { auth } from 'firebase-export';

const ReAuthForm = ({ onAuthSuccess }) => {
  const [isAuthLoading, setIsAuthLoading] = useState(false);
  const [multiFactorResolver, setMultiFactorResolver] = useState();
  const [verificationId, setVerificationId] = useState();

  const appVerifier = useRecaptchaVerifier(
    multiFactorResolver
      ? 'verification-code-submit-button'
      : 'confirm-password-button'
  );

  const onConfirmPassword = async (password) => {
    setIsAuthLoading(true);
    try {
      // Re-authenticate the user
      const { currentUser } = await auth();
      await currentUser.reauthenticateWithCredential(
        auth.EmailAuthProvider.credential(currentUser.email, password)
      );

      onAuthSuccess();
    } catch (error) {
      if (error.code === 'auth/multi-factor-auth-required') {
        const { resolver } = error;
        try {
          const phoneInfoOptions = {
            multiFactorHint: resolver.hints[0],
            session: resolver.session,
          };
          const phoneAuthProvider = new auth.PhoneAuthProvider();
          // Send SMS verification code
          const res = await phoneAuthProvider.verifyPhoneNumber(
            phoneInfoOptions,
            appVerifier
          );
          setVerificationId(res);
          setMultiFactorResolver(resolver);
        } catch (codeErr) {
          message.error(codeErr.message);
        }
        setIsAuthLoading(false);
      } else {
        setIsAuthLoading(false);
        message.error(error.message);
      }
    }
  };

  const resolveMFASignIn = async (multiFactorAssertion) => {
    // Complete sign-in.
    await multiFactorResolver.resolveSignIn(multiFactorAssertion);
    onAuthSuccess();
  };

  return multiFactorResolver ? (
    <SMSVerifyForm
      verificationId={verificationId}
      submitButtonText="Next"
      onVerifySuccess={resolveMFASignIn}
    />
  ) : (
    <Form
      layout="vertical"
      onFinish={(values) => onConfirmPassword(values.password)}
    >
      <Form.Item
        name="password"
        label="Password"
        rules={[{ required: true, message: 'Please input your password.' }]}
      >
        <Input.Password placeholder="Password" />
      </Form.Item>
      <Form.Item>
        <Button
          id="confirm-password-button"
          block
          loading={isAuthLoading}
          type="primary"
          htmlType="submit"
        >
          Confirm password
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ReAuthForm;
