/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  PageHeader,
  message,
  Descriptions,
  Skeleton,
  Tag,
  Popconfirm,
  Switch,
  Card,
  Button,
  Typography,
  Modal,
  Dropdown,
  Menu,
} from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import moment from 'moment-timezone';
import copy from 'copy-to-clipboard';

import {
  getSuperAdminViewUser,
  updateUserSuperAdminAccess,
  updateUserAccountStatus,
  generateUserPasswordResetLink,
  getAPIErrMsg,
} from 'api';
import { SuperAdminViewUser } from 'types';
import { UserProviderIcons, Container } from 'components';
import { getUserDisplayName } from 'utils';
import { UserOrgsTable, AccountDeletionModal } from './components';

type MenuKey = 'delete';

export const EditUserInSuperAdmin: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [user, setUser] = useState<SuperAdminViewUser | null>(null);
  const [isUpdatingSuperAdminAccess, setIsUpdatingSuperAdminAccess] =
    useState<boolean>(false);
  const [isUpdatingAccountStatus, setIsUpdatingAccountStatus] =
    useState<boolean>(false);
  const [isAccountDeletionModalOpen, setIsAccountDeletionModalOpen] =
    useState<boolean>(false);
  const [isGeneratingPasswordResetLink, setIsGeneratingPasswordResetLink] =
    useState<boolean>(false);

  const { userId } = useParams<{ userId?: string }>();
  const history = useHistory();

  useEffect(() => {
    const fetchUser = async () => {
      if (userId) {
        setIsLoading(true);
        try {
          const userData = await getSuperAdminViewUser(userId);
          setUser(userData);
        } catch (error) {
          message.error(getAPIErrMsg(error));
        }
        setIsLoading(false);
      }
    };
    fetchUser();
  }, [userId]);

  const onMenuClick = (key: MenuKey) => {
    switch (key) {
      case 'delete':
        if (!user?.disabled) {
          Modal.warn({ title: 'Account needs to be disabled before deleted' });
        } else {
          setIsAccountDeletionModalOpen(true);
        }
        break;
      default:
        break;
    }
  };

  const onSuperAdminAccessChange = async () => {
    if (userId) {
      setIsUpdatingSuperAdminAccess(true);
      try {
        await updateUserSuperAdminAccess(userId, !user?.isSuper);
        // Update user state
        setUser(
          (prevUser) => prevUser && { ...prevUser, isSuper: !user?.isSuper }
        );
        message.success('Super Admin Access Updated');
      } catch (error) {
        message.error(getAPIErrMsg(error));
      }
      setIsUpdatingSuperAdminAccess(false);
    }
  };

  const onAccountStatusChange = async () => {
    if (userId) {
      setIsUpdatingAccountStatus(true);
      try {
        await updateUserAccountStatus(userId, !user?.disabled);
        // Update user state
        setUser(
          (prevUser) => prevUser && { ...prevUser, disabled: !user?.disabled }
        );
        message.success('User Account Status Updated');
      } catch (error) {
        message.error(getAPIErrMsg(error));
      }
      setIsUpdatingAccountStatus(false);
    }
  };

  const onPasswordResetLinkGenerate = async () => {
    if (userId) {
      setIsGeneratingPasswordResetLink(true);
      try {
        const link = await generateUserPasswordResetLink(userId);
        copy(link);
        message.success('Link copied to clipboard');
      } catch (error) {
        message.error(getAPIErrMsg(error));
      }
      setIsGeneratingPasswordResetLink(false);
    }
  };

  const userDisplayName = getUserDisplayName(user);

  const isMFAEnabled = !!user?.multiFactor?.enrolledFactors?.length;

  return (
    <div>
      <PageHeader
        ghost={false}
        title={userDisplayName}
        onBack={() => history.push('/superadmin/users')}
        extra={[
          <Button
            key="password-reset"
            loading={isGeneratingPasswordResetLink}
            onClick={onPasswordResetLinkGenerate}
          >
            Generate Password Reset Link
          </Button>,
          <Dropdown
            key="dropdown"
            trigger={['click']}
            overlay={
              <Menu onClick={({ key }) => onMenuClick(key as MenuKey)}>
                <Menu.Item key="delete" danger>
                  Delete Account
                </Menu.Item>
              </Menu>
            }
          >
            <Button icon={<EllipsisOutlined />} />
          </Dropdown>,
        ]}
      >
        <Skeleton loading={isLoading}>
          <Descriptions>
            <Descriptions.Item label="Name">
              {userDisplayName}
            </Descriptions.Item>
            <Descriptions.Item label="Email">{user?.email}</Descriptions.Item>
            <Descriptions.Item label="Created At">
              {user?.createdAt &&
                moment(user.createdAt).format('DD/MM/YYYY HH:mm:ss')}
            </Descriptions.Item>
            <Descriptions.Item label="Last Login">
              {user?.lastSignInTime &&
                moment(user.lastSignInTime).format('DD/MM/YYYY HH:mm:ss')}
            </Descriptions.Item>
            <Descriptions.Item label="Is Super Admin">
              <Popconfirm
                title={
                  user?.isSuper
                    ? `'Are you sure you want to remove Super Admin access for this ${userDisplayName}?'`
                    : `Are you sure you want to make ${userDisplayName} a Super Admin?`
                }
                onConfirm={onSuperAdminAccessChange}
                okText={user?.isSuper ? 'Remove' : 'Yes'}
                okButtonProps={{ danger: !!user?.isSuper }}
              >
                <Switch
                  checked={!!user?.isSuper}
                  loading={isUpdatingSuperAdminAccess}
                  checkedChildren="Yes"
                  unCheckedChildren="No"
                />
              </Popconfirm>
            </Descriptions.Item>
            <Descriptions.Item label="Providers">
              <UserProviderIcons providers={user?.providerData || []} />
            </Descriptions.Item>
            <Descriptions.Item label="MFA">
              <Tag color={isMFAEnabled ? 'success' : 'default'}>
                {isMFAEnabled ? 'Active' : 'Disabled'}
              </Tag>
            </Descriptions.Item>
            <Descriptions.Item label="User ID">
              <Typography.Text copyable>{user?.id}</Typography.Text>
            </Descriptions.Item>
            <Descriptions.Item label="Account Status">
              <Popconfirm
                title={`Are you sure you want to ${
                  user?.disabled ? 'active' : 'disable'
                } ${userDisplayName}'s account?`}
                onConfirm={onAccountStatusChange}
                okText={user?.disabled ? 'Active' : 'Disable'}
                okButtonProps={{ danger: !user?.disabled }}
              >
                <Switch
                  checked={!user?.disabled}
                  loading={isUpdatingAccountStatus}
                  checkedChildren="Active"
                  unCheckedChildren="Disabled"
                />
              </Popconfirm>
            </Descriptions.Item>
          </Descriptions>
        </Skeleton>
      </PageHeader>

      <Container paddingY={3}>
        <Card title="Organisations">
          <UserOrgsTable user={user} setUser={setUser} isLoading={isLoading} />
        </Card>
      </Container>

      <AccountDeletionModal
        user={user}
        visible={isAccountDeletionModalOpen}
        onCancel={() => setIsAccountDeletionModalOpen(false)}
      />
    </div>
  );
};
