/** @jsxImportSource @emotion/react */
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import firebase from 'firebase/app';

// import Ant design
import { Collapse, Tooltip, message } from 'antd';
import { CheckCircleTwoTone, CheckCircleOutlined } from '@ant-design/icons';

import { auth } from 'firebase-export';
import { arrayToObject } from 'utils';
import { FirebaseAuthError } from 'types';
import ChangePasswordForm from './components/ChangePasswordForm';
import MFAConfig from './components/MFAConfig';
import MicrosoftSignInConfig from './components/MicrosoftSignInConfig';
import GoogleSignInConfig from './components/GoogleSignInConfig';

type PanelKey = 'email-psw' | 'mfa' | 'microsoft-sso' | 'google';

const enableMicrosoftLogin = false;
const enableGoogleLogin = true;

const Security = () => {
  const location = useLocation();

  const { open }: { open?: PanelKey } = queryString.parse(location.search);

  const [user, setUser] = useState<firebase.User | null>(null);
  const [openPanel, setOpenPanel] = useState<PanelKey | undefined>(open);
  const [userRefreshAt, setUserRefreshAt] = useState<number>(Date.now());

  useEffect(() => {
    const getUser = async () => {
      const { currentUser } = await auth();
      setUser(currentUser);
    };

    getUser();
  }, [userRefreshAt]);

  // Handle linkWithRedirect result
  useEffect(() => {
    const getRedirectResult = async () => {
      try {
        const res = await auth().getRedirectResult();
        if (res.credential) {
          if (res.credential.providerId.includes('microsoft')) {
            setOpenPanel('microsoft-sso');
          } else if (res.credential.providerId.includes('google')) {
            setOpenPanel('google');
          }
          message.success('Link success');
        }
      } catch (error) {
        message.error((error as FirebaseAuthError)?.message || 'Link failed');
      }
    };

    getRedirectResult();
  }, []);

  const refreshUserData = () => {
    setUserRefreshAt(Date.now());
  };

  const configured = (
    <Tooltip title="Configured">
      <CheckCircleTwoTone />
    </Tooltip>
  );

  const notConfigured = (
    <Tooltip title="Not Configured">
      <CheckCircleOutlined />
    </Tooltip>
  );

  const providers = (user?.providerData || []).filter(
    (provider) => provider
  ) as firebase.UserInfo[];

  const providersObj: { [providerId: string]: firebase.UserInfo } =
    arrayToObject(providers, 'providerId');

  const multiFactors = user?.multiFactor?.enrolledFactors || [];

  return (
    <Collapse
      activeKey={openPanel}
      onChange={(panel) =>
        Array.isArray(panel)
          ? setOpenPanel(panel[0] as PanelKey)
          : setOpenPanel(panel as PanelKey)
      }
    >
      <Collapse.Panel
        key="email-psw"
        header="Password"
        extra={providersObj.password ? configured : notConfigured}
      >
        <ChangePasswordForm />
      </Collapse.Panel>
      <Collapse.Panel
        key="mfa"
        header="Two-Factor Authentication"
        extra={multiFactors.length > 0 ? configured : notConfigured}
      >
        <MFAConfig
          disabled={
            providers.filter((prov) => prov.providerId !== 'password').length >
            0
          }
          multiFactors={multiFactors}
          refreshUserData={refreshUserData}
        />
      </Collapse.Panel>
      {enableMicrosoftLogin && (
        <Collapse.Panel
          key="microsoft-sso"
          header="Microsoft SSO"
          extra={providersObj['microsoft.com'] ? configured : notConfigured}
        >
          <MicrosoftSignInConfig
            disabled={multiFactors.length > 0}
            provider={providersObj['microsoft.com']}
            refreshUserData={refreshUserData}
          />
        </Collapse.Panel>
      )}
      {enableGoogleLogin && (
        <Collapse.Panel
          key="google"
          header="Google Sign-In"
          extra={providersObj['google.com'] ? configured : notConfigured}
        >
          <GoogleSignInConfig
            disabled={multiFactors.length > 0}
            provider={providersObj['google.com']}
            refreshUserData={refreshUserData}
          />
        </Collapse.Panel>
      )}
    </Collapse>
  );
};

export default Security;
