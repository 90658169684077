/** @jsxImportSource @emotion/react */
import { useState } from 'react';

import { Modal } from 'antd';

import ReAuthForm from './ReAuthForm';
import PhoneSetupForm from './PhoneSetupForm';
import ConfirmSMSRemove from './ConfirmSMSRemove';

const SMSFactorConfigModal = ({
    selectedFactor,
    visible = false,
    onCancel,
}) => {
    const [modalTitle, setModalTitle] = useState('Confirm password to continue');
    const [step, setStep] = useState('confirmPassword');

    const onAuthSuccess = () => {
        setModalTitle(selectedFactor ? 'Remove SMS MFA' : 'Set up your phone');
        setStep(selectedFactor ? 'confirmRemove' : 'setupPhone');
    };

    const renderStep = () => {
        switch (step) {
            case 'confirmPassword':
                return (
                    <ReAuthForm onAuthSuccess={onAuthSuccess} />
                );
            case 'confirmRemove':
                return (
                    <ConfirmSMSRemove
                        selectedFactor={selectedFactor}
                        onRemoveFinish={onCancel}
                    />
                );
            case 'setupPhone':
                return <PhoneSetupForm onSetupFinish={onCancel} />;
            default:
                return null;
        }
    };

    return (
        <Modal
            destroyOnClose
            title={modalTitle}
            visible={visible}
            onCancel={onCancel}
            footer={null}
        >
            {renderStep()}
        </Modal>
    );
};

export default SMSFactorConfigModal;
