import { OrgRole, ProductKey, ProductRole } from '@eliiza-thea/auth-wrapper';

import { UserRoles } from 'types';

export const transformUserRoles = (
  userRoles: UserRoles
): { orgRole: OrgRole } & {
  [key in ProductKey]?: { productRole: ProductRole };
} => {
  const { orgRole, productRole } = userRoles;
  return {
    orgRole,
    ...(['insight', 'data', 'annotator'] as ProductKey[]).reduce(
      (prev, productKey) => ({
        ...prev,
        ...(productRole[productKey]
          ? { [productKey]: { productRole: productRole[productKey] } }
          : {}),
      }),
      {}
    ),
  };
};
