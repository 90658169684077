/** @jsxImportSource @emotion/react */
import { useState, useMemo, useEffect } from 'react';
import { Table, Row, Col, Input, Button, message } from 'antd';
import { Link } from 'react-router-dom';
import { Organisation } from '@eliiza-thea/auth-wrapper';
import moment from 'moment-timezone';

import { Container } from 'components';
import { preset } from 'styles';
import { getAllOrgs, getAPIErrMsg } from 'api';

export const Organisations = () => {
  const [orgs, setOrgs] = useState<Organisation[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>('');

  useEffect(() => {
    const fetchAllOrgs = async () => {
      setIsLoading(true);
      try {
        const orgsData = await getAllOrgs();
        setOrgs(orgsData);
      } catch (error) {
        message.error(getAPIErrMsg(error));
      }
      setIsLoading(false);
    };
    fetchAllOrgs();
  }, []);

  const filteredOrgs = useMemo(() => {
    if (!searchText) return orgs;
    return orgs.filter((org) => {
      return !![org.name, org.id].find((orgString) =>
        orgString.toLowerCase().includes(searchText.toLowerCase())
      );
    });
  }, [searchText, orgs]);

  return (
    <Container paddingY={3}>
      <Row justify="space-between" css={{ marginBottom: preset.spacing(3) }}>
        <Col span={10}>
          <Input.Search
            allowClear
            placeholder="Search"
            onSearch={setSearchText}
          />
        </Col>
        <Col>
          <Button type="primary">
            <Link to="/superadmin/organisations/create">
              Create Organisation
            </Link>
          </Button>
        </Col>
      </Row>
      <Table
        loading={isLoading}
        columns={[
          {
            title: 'Name',
            dataIndex: 'name',
            render: (name, row) => (
              <Link to={`/superadmin/organisations/${row.id}`}>{name}</Link>
            ),
          },
          { title: 'Slug', dataIndex: 'id' },
          {
            title: 'Created At',
            dataIndex: 'createdAt',
            render: (createdAt: Organisation['createdAt']) =>
              createdAt && moment(createdAt).format('DD/MM/YYYY'),
          },
        ]}
        rowKey="id"
        dataSource={filteredOrgs}
        locale={{ emptyText: 'No Organisation Found' }}
        pagination={{
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`,
        }}
      />
    </Container>
  );
};
