/** @jsxImportSource @emotion/react */
import { useState, useMemo } from 'react';

// import Ant design
import { Button, List, Alert } from 'antd';
import { MessageOutlined } from '@ant-design/icons';

import { preset } from 'styles';
import SMSFactorConfigModal from './SMSFactorConfigModal';

const MFAConfig = ({ disabled = false, multiFactors, refreshUserData }) => {
  const [isSMSConfigModalOpen, setIsSMSConfigModalOpen] = useState(false);
  const [selectedFactor, setSelectedFactor] = useState();

  const onSMSConfigModalClose = () => {
    setIsSMSConfigModalOpen(false);
    refreshUserData();
  };

  const mfaListData = useMemo(() => {
    const defaultSMSFactor = {
      title: 'SMS',
      description: 'Get codes by text message',
      icon: <MessageOutlined />,
    };

    const SMSFactor = multiFactors.find(
      (factor) => factor.factorId === 'phone'
    );

    if (SMSFactor) {
      return [
        {
          ...defaultSMSFactor,
          description: SMSFactor.phoneNumber,
          factor: SMSFactor,
        },
      ];
    }

    return [defaultSMSFactor];
  }, [multiFactors]);

  return (
    <>
      {disabled && (
        <Alert
          showIcon
          type="info"
          message="Two-Factor authentication is disabled when there are other sign-in providers linked (e.g. Microsoft SSO, Google Sign-In)"
          css={{ marginBottom: preset.spacing(1) }}
        />
      )}
      <List
        itemLayout="horizontal"
        dataSource={mfaListData}
        renderItem={(item) => (
          <List.Item>
            <List.Item.Meta
              title={item.title}
              description={
                item.factor ? (
                  <span>
                    {item.description}
                    <span
                      css={{
                        marginLeft: preset.spacing(1),
                      }}
                    >
                      Verified
                    </span>
                  </span>
                ) : (
                  item.description
                )
              }
              avatar={item.icon}
            />
            <Button
              danger={!!item.factor}
              type="primary"
              disabled={disabled}
              onClick={() => {
                setIsSMSConfigModalOpen(true);
                setSelectedFactor(item.factor);
              }}
            >
              {item.factor ? 'Remove' : 'Set Up'}
            </Button>
          </List.Item>
        )}
      />

      <SMSFactorConfigModal
        selectedFactor={selectedFactor}
        visible={isSMSConfigModalOpen}
        onCancel={onSMSConfigModalClose}
      />
    </>
  );
};

export default MFAConfig;
