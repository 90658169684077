/** @jsxImportSource @emotion/react */
import { useHistory } from 'react-router-dom';

import {
    Menu,
} from 'antd';

const AccountMenu = ({
    currentMenu,
}) => {
    const history = useHistory();

    return (
        <Menu
            mode="inline"
            defaultSelectedKeys={[currentMenu]}
            onClick={({ key }) => history.push(`/account/${ key }`)}
        >
            <Menu.Item key="general">
                General
            </Menu.Item>
            <Menu.Item key="security">
                Security
            </Menu.Item>
        </Menu>
    );
};

export default AccountMenu;
