/** @jsxImportSource @emotion/react */
import { useState } from 'react';

import { Button, message } from 'antd';

import { preset } from 'styles';
import { auth } from 'firebase-export';

const ConfirmSMSRemove = ({ selectedFactor, onRemoveFinish }) => {
  const [isLoading, setIsLoading] = useState(false);

  const onRemove = async () => {
    setIsLoading(true);
    try {
      const { currentUser } = await auth();
      await currentUser.multiFactor.unenroll(selectedFactor);
      message.success('SMS MFA Removed');
      onRemoveFinish();
    } catch (error) {
      setIsLoading(false);
      message.error(error.message);
    }
  };

  return (
    <div>
      {selectedFactor && (
        <div css={{ marginBottom: preset.spacing(2) }}>
          {selectedFactor.phoneNumber}
          <span
            css={{
              marginLeft: preset.spacing(1),
            }}
          >
            Verified
          </span>
        </div>
      )}
      <Button danger type="primary" loading={isLoading} onClick={onRemove}>
        Remove SMS MFA
      </Button>
    </div>
  );
};

export default ConfirmSMSRemove;
