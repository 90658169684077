/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Row, Col, Select, Form, FormInstance, message } from 'antd';
import capitalize from 'lodash/capitalize';
import { OrgRole, Organisation } from '@eliiza-thea/auth-wrapper';
import queryString from 'query-string';

import { preset } from 'styles';
import { getAllOrgs, getAPIErrMsg } from 'api';

type Props = { form: FormInstance; showOrgSelect?: boolean };

export const UserRoleFormItems: React.FC<Props> = ({
  form,
  showOrgSelect = false,
}) => {
  const [orgs, setOrgs] = useState<Organisation[]>([]);

  const location = useLocation();

  const { disabledOrgs }: { disabledOrgs?: string[] } = queryString.parse(
    location.search
  );

  useEffect(() => {
    const fetchAllOrgs = async () => {
      if (showOrgSelect) {
        try {
          const orgsData = await getAllOrgs();
          setOrgs(orgsData);
        } catch (error) {
          message.error(getAPIErrMsg(error));
        }
      }
    };
    fetchAllOrgs();
  }, [showOrgSelect]);

  const onOrgRoleChange = (value: OrgRole) => {
    if (value === 'admin') {
      form.setFieldsValue({
        productRole: { insight: 'admin', data: 'admin', annotator: 'admin' },
      });
    }
  };

  return (
    <>
      <Row gutter={preset.spacing(3)}>
        {showOrgSelect && (
          <Col span={8}>
            <Form.Item
              name="orgSlug"
              label="Organisation"
              rules={[
                {
                  required: true,
                  message: 'Please select an organisation',
                },
              ]}
            >
              <Select
                placeholder="Select an organisation"
                options={orgs.map((org) => ({
                  label: org.name,
                  value: org.id,
                  disabled: !!disabledOrgs?.includes(org.id),
                }))}
              />
            </Form.Item>
          </Col>
        )}
        <Col span={8}>
          <Form.Item
            name="orgRole"
            label="Organisation Role"
            rules={[
              {
                required: true,
                message: 'Please select an organisation role',
              },
            ]}
          >
            <Select
              placeholder="Select an organisation role"
              onChange={onOrgRoleChange}
              options={[
                { label: 'Admin', value: 'admin' },
                { label: 'User', value: 'user' },
              ]}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={preset.spacing(3)}>
        {['insight', 'data', 'annotator'].map((productKey) => (
          <Col key={productKey} span={8}>
            <Form.Item
              name={['productRole', productKey]}
              label={`${capitalize(productKey)} Role`}
            >
              <Select
                placeholder="No Access"
                allowClear
                options={[
                  { label: 'Admin', value: 'admin' },
                  { label: 'Analyst', value: 'analyst' },
                  { label: 'User', value: 'user' },
                  { label: 'No Access', value: '' },
                ]}
              />
            </Form.Item>
          </Col>
        ))}
      </Row>
    </>
  );
};
