import { app } from 'firebase-export';

export const uploadOrgLogo = async (
  imgFile: File,
  orgSlug: string
): Promise<string> => {
  const storageRef = app().storage(`gs://otso-data-${orgSlug}`).ref();
  const fileExt = imgFile.name.split('.').pop();
  const fileName = `logo.${fileExt}`;
  const uploadPath = `system/${fileName}`;
  await storageRef
    .child(uploadPath)
    .put(imgFile, { contentType: imgFile.type });
  return fileName;
};
