/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Form, Card, Row, Col, Input, Button, Space, message } from 'antd';
import { OrgRole, ProductRole, ProductKey } from '@eliiza-thea/auth-wrapper';
import pickBy from 'lodash/pickBy';
import queryString from 'query-string';

import { preset } from 'styles';
import { UserRoleFormItems } from 'components';
import { addUserToCurrentOrg, addUserToOrg, getAPIErrMsg } from 'api';
import { AdminViewUser } from 'types';
import { useIsSuperAdminRoute } from 'hooks';

export const NewUserForm: React.FC = () => {
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const location = useLocation();
  const history = useHistory();

  const [form] = Form.useForm();

  const isSuperAdminRoute = useIsSuperAdminRoute();

  const {
    orgSlug: defaultOrgSlug,
    email: defaultEmail,
    firstName: defaultFirstName,
    lastName: defaultLastName,
  }: {
    orgSlug?: string;
    email?: string;
    firstName?: string;
    lastName?: string;
  } = queryString.parse(location.search);

  const onAddUserToCurrentOrg = async (values: {
    email: string;
    firstName: string;
    lastName: string;
    orgRole: OrgRole;
    productRole: { [key in ProductKey]?: ProductRole | '' };
  }) => {
    const formattedUser: Partial<AdminViewUser> = {
      email: values.email.trim(),
      firstName: values.firstName.trim(),
      lastName: values.lastName.trim(),
      orgRole: values.orgRole,
      productRole: pickBy(values.productRole, (val) => !!val),
    };
    setIsSaving(true);
    try {
      const newUserId = await addUserToCurrentOrg(formattedUser);
      message.success('User Added');
      history.push(`/users/${newUserId}`);
    } catch (error) {
      setIsSaving(false);
      message.error(getAPIErrMsg(error));
    }
  };

  const onAddUserToOrg = async (values: {
    email: string;
    firstName: string;
    lastName: string;
    orgSlug: string;
    orgRole: OrgRole;
    productRole: { [key in ProductKey]?: ProductRole };
  }) => {
    const formattedUser = {
      email: values.email.trim(),
      firstName: values.firstName.trim(),
      lastName: values.lastName.trim(),
      orgRole: values.orgRole,
      productRole: pickBy(values.productRole, (val) => !!val),
    };
    setIsSaving(true);
    try {
      const newUserId = await addUserToOrg(formattedUser, values.orgSlug);
      message.success('User Added');
      if (defaultOrgSlug) {
        history.push(`/superadmin/organisations/${defaultOrgSlug}`);
      } else {
        history.push(`/superadmin/users/${newUserId}`);
      }
    } catch (error) {
      setIsSaving(false);
      message.error(getAPIErrMsg(error));
    }
  };

  return (
    <Form
      layout="vertical"
      form={form}
      initialValues={{
        ...(defaultOrgSlug ? { orgSlug: defaultOrgSlug } : {}),
        email: defaultEmail,
        confirmEmail: defaultEmail,
        firstName: defaultFirstName,
        lastName: defaultLastName,
      }}
      onFinish={isSuperAdminRoute ? onAddUserToOrg : onAddUserToCurrentOrg}
    >
      <Card
        title="General"
        bordered={false}
        css={{ marginBottom: preset.spacing(3) }}
      >
        <Row gutter={preset.spacing(3)}>
          <Col span={8}>
            <Form.Item
              label="Email"
              name="email"
              rules={[
                { required: true, message: 'Please input your email' },
                { type: 'email', message: 'Invalid Email' },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              name="confirmEmail"
              label="Confirm Email"
              dependencies={['email']}
              hasFeedback
              rules={[
                { required: true, message: 'Please confirm your email' },
                ({ getFieldValue }) => ({
                  validator: (rule, value) => {
                    if (!value || getFieldValue('email') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('Email does not match'));
                  },
                }),
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={8} />

          <Col span={8}>
            <Form.Item
              label="First name"
              name="firstName"
              rules={[
                {
                  required: true,
                  message: 'Please input your first name',
                  whitespace: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label="Last name"
              name="lastName"
              rules={[
                {
                  required: true,
                  message: 'Please input your last name',
                  whitespace: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Card>

      <Card
        title={isSuperAdminRoute ? 'Organisation & Roles' : 'Roles'}
        bordered={false}
        css={{ marginBottom: preset.spacing(3) }}
      >
        <UserRoleFormItems form={form} showOrgSelect={isSuperAdminRoute} />
      </Card>

      <Form.Item>
        <Space size="middle">
          <Button type="primary" htmlType="submit" loading={isSaving}>
            Create User
          </Button>
          <Button
            onClick={() =>
              isSuperAdminRoute
                ? history.push('/superadmin/users')
                : history.push('/users')
            }
          >
            Cancel
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};
