/** @jsxImportSource @emotion/react */

// Import Ant Design components
import { Space, Typography } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

// Import stylesheets
import styles from './styles';

// Import additional Ant Design components
const { Text } = Typography;

const FormErrorMessage = ({ text }) => {
    return (
        <Space style={styles.errorMessage}>
            <ExclamationCircleOutlined style={styles.errorIcon} />
            <Text style={styles.errorText}>{text}</Text>
        </Space>
    );
};

export default FormErrorMessage;
