/** @jsxImportSource @emotion/react */
import { useState } from 'react';
import { useAuth } from '@eliiza-thea/auth-wrapper';

// import Ant design
import { message, Form, Input, Button } from 'antd';

import { TimezoneSelect } from 'components';
import { updateMyDetails, updateMyTimezone, getAPIErrMsg } from 'api';

const General = () => {
  const [isSavingUser, setIsSavingUser] = useState<boolean>(false);

  const { user, firebaseUser } = useAuth();

  const { email, firstName, lastName, timezone } = user || {};

  const saveUser = async (values: {
    firstName: string;
    lastName: string;
    timezone?: string;
  }) => {
    setIsSavingUser(true);
    try {
      await updateMyDetails(values.firstName, values.lastName);

      if (firebaseUser) {
        await firebaseUser.updateProfile({
          displayName: `${firstName} ${lastName}`,
        });
      }
    } catch (error) {
      message.error((error as Error).message);
    }

    if (timezone !== values.timezone) {
      try {
        await updateMyTimezone(values.timezone || 'Australia/Sydney');
      } catch (error) {
        message.error(getAPIErrMsg(error));
      }
    }

    setIsSavingUser(false);
  };

  return (
    <Form
      layout="vertical"
      onFinish={saveUser}
      initialValues={{
        email,
        firstName,
        lastName,
        timezone,
      }}
    >
      <Form.Item name="email" label="Email">
        <Input name="email" disabled />
      </Form.Item>
      <Form.Item
        name="firstName"
        label="First name"
        rules={[{ required: true, message: 'Please input your first name' }]}
      >
        <Input name="firstName" />
      </Form.Item>
      <Form.Item
        name="lastName"
        label="Last name"
        rules={[{ required: true, message: 'Please input your first name' }]}
      >
        <Input name="lastName" />
      </Form.Item>
      <Form.Item name="timezone" label="Time zone">
        <TimezoneSelect />
      </Form.Item>
      <Form.Item name="submit">
        <Button type="primary" htmlType="submit" loading={isSavingUser}>
          Save
        </Button>
      </Form.Item>
    </Form>
  );
};

export default General;
