/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Modal, Alert, Typography, Form, Input, message } from 'antd';

import { SuperAdminViewUser } from 'types';
import { getUserDisplayName } from 'utils';
import { preset } from 'styles';
import { deleteUserAccount, getAPIErrMsg } from 'api';

type Props = {
  user: SuperAdminViewUser | null;
  visible: boolean;
  onCancel: () => void;
};

export const AccountDeletionModal: React.FC<Props> = ({
  user,
  visible,
  onCancel,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const history = useHistory();

  const [form] = Form.useForm();

  const onAccountDelete = async () => {
    if (user) {
      setIsLoading(true);
      try {
        await form.validateFields();
        await deleteUserAccount(user.id);
        history.push('/superadmin/users');
        message.success('User Deleted');
      } catch (error) {
        setIsLoading(false);
        // No error alert if it's antd form error
        if (!(error as any).errorFields) {
          message.error(getAPIErrMsg(error));
        }
      }
    }
  };

  return (
    <Modal
      title="Account Deletion"
      visible={visible}
      onCancel={onCancel}
      onOk={onAccountDelete}
      okText="Delete"
      okButtonProps={{ danger: true, loading: isLoading }}
    >
      <Typography.Title
        level={5}
      >{`Are you sure you want to delete ${getUserDisplayName(
        user
      )}'s account?`}</Typography.Title>
      <Alert
        type="warning"
        message={
          <Typography>
            <Typography.Paragraph>
              This will
              <b>&nbsp;permanently delete&nbsp;</b>
              the account from all organisations and the user will no longer be
              able to login. Please check that the user is not the only user or
              admin in any of their organisations as this could leave the
              organisation in an orphaned state or cause issues for other users.
            </Typography.Paragraph>
            <Typography.Text>
              If you need to re-add this user in the future you will need to
              manually add them back into all organisations and projects.
            </Typography.Text>
          </Typography>
        }
        css={{ marginTop: preset.spacing(2), marginBottom: preset.spacing(2) }}
      />
      <Form layout="vertical" form={form}>
        <Form.Item
          label={`Confirm delete by typing ${getUserDisplayName(
            user
          )}'s email address`}
          name="email"
          rules={[
            { required: true, message: 'Please input email' },
            {
              validator: (rule, value) => {
                if (!value || value === user?.email) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('Email does not match'));
              },
            },
          ]}
          css={{ marginBottom: 0 }}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};
