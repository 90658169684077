// Obtain the deployment target from environment variable
const deploymentTarget = process.env.REACT_APP_AUTH_ENV;

const globals: Record<string, any> = {
  dev: {
    doNotShowDashboard: false,
  },
  app: {
    doNotShowDashboard: false,
  },
  dpcsa: {
    doNotShowDashboard: true,
  },
  global: {
    doNotShowDashboard: false,
  },
};

export const getGlobals = () => {
  if (!deploymentTarget) return globals.global;
  return {
    ...globals.global,
    ...(globals[deploymentTarget] || {}),
  };
};
