// Import Ant Design components
import {
  DatabaseOutlined,
  FormOutlined,
  AreaChartOutlined,
  TranslationOutlined,
} from '@ant-design/icons';

// Extract values from environment variables
const { NODE_ENV, REACT_APP_AUTH_ENV } = process.env;

/**
 * Get a subdomain for the product based on the current environment.
 * @param {string} productName Product name
 * @returns a subdomain for the product based on the current environment
 */
const getSubdomain = (productName) => {
  if (REACT_APP_AUTH_ENV === 'dev') return `${productName}-dev`;

  return productName;
};

const otsoData = {
  name: 'otso Data',
  host:
    NODE_ENV === 'production'
      ? `https://${getSubdomain('data')}.otso.ai`
      : 'http://localhost:3001',
  icon: <DatabaseOutlined />,
};

const otsoAnnotator = {
  name: 'otso Annotator',
  host:
    NODE_ENV === 'production'
      ? `https://${getSubdomain('annotator')}.otso.ai`
      : 'http://localhost:3002',
  icon: <FormOutlined />,
};

const otsoNLP = {
  name: 'otso NLP',
  host:
    NODE_ENV === 'production'
      ? `https://${getSubdomain('nlp')}.otso.ai`
      : 'http://localhost:3003',
  icon: <TranslationOutlined />,
};

const otsoInsight = {
  name: 'otso Insight',
  host:
    NODE_ENV === 'production'
      ? `https://${getSubdomain('insight')}.otso.ai`
      : 'http://localhost:3004',
  icon: <AreaChartOutlined />,
};

// Prepare a list of products
export const products = [otsoData, otsoAnnotator, otsoNLP, otsoInsight];

// Prepare a list of categories and products
export const categories = [
  {
    name: 'Data Management and Processing',
    products: [otsoData],
  },
  {
    name: 'Text Annotations',
    products: [otsoAnnotator],
  },
  {
    name: 'Machine Learning',
    products: [otsoNLP],
  },
  {
    name: 'Data Analytics',
    products: [otsoInsight],
  },
];
