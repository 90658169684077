import { User } from '@eliiza-thea/auth-wrapper';
import { AdminViewUser, SuperAdminViewUser } from 'types';

export const getUserDisplayName = (
  user?: User | AdminViewUser | SuperAdminViewUser | null
) => {
  return (
    user?.displayName || `${user?.firstName || ''} ${user?.lastName || ''}`
  );
};
