/** @jsxImportSource @emotion/react */
import { useState } from 'react';
import { Form, Input, Button, Alert, message } from 'antd';

import { preset } from 'styles';
import { auth } from 'firebase-export';

const SMSVerifyForm = ({
  verificationId,
  submitButtonId = 'verification-code-submit-button',
  submitButtonText = 'Submit',
  onVerifySuccess,
  onVerifyFail,
  onResend,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isResendLoading, setIsResendLoading] = useState(false);
  const [verifyErr, setVerifyErr] = useState();

  const onVerify = async (verificationCode) => {
    setIsLoading(true);
    try {
      // Ask user for the SMS verification code.
      const cred = auth.PhoneAuthProvider.credential(
        verificationId,
        verificationCode
      );
      const multiFactorAssertion =
        auth.PhoneMultiFactorGenerator.assertion(cred);
      await onVerifySuccess(multiFactorAssertion);
    } catch (error) {
      setIsLoading(false);
      if (onVerifyFail) {
        onVerifyFail(error.message);
      } else {
        message.error(error.message);
      }
      setVerifyErr(error);
    }
  };

  const onResendButtonClick = async () => {
    setIsResendLoading(true);
    try {
      await onResend();
      setVerifyErr();
      if (onVerifyFail) {
        onVerifyFail();
      }
    } catch (error) {
      if (onVerifyFail) {
        onVerifyFail(error.message);
      } else {
        message.error(error.message);
      }
      setVerifyErr(error);
    }
    setIsResendLoading(false);
  };

  return (
    <Form
      key={verificationId}
      layout="vertical"
      onFinish={(values) => onVerify(values.code)}
    >
      {!verifyErr && (
        <Alert
          showIcon
          type="info"
          message="otso just sent a text message with a verification code to your phone"
          css={{ marginBottom: preset.spacing(2) }}
        />
      )}
      <Form.Item
        name="code"
        label="SMS verification code"
        rules={[{ required: true, message: 'Please enter the code.' }]}
      >
        <Input placeholder="Enter the code" />
      </Form.Item>
      {verifyErr && !!onResend && (
        <Form.Item
          css={{
            marginTop: -preset.spacing(2),
            marginBottom: preset.spacing(2),
          }}
        >
          <Button
            type="link"
            loading={isResendLoading}
            onClick={onResendButtonClick}
            css={{ paddingLeft: 0 }}
          >
            Resend code
          </Button>
        </Form.Item>
      )}
      <Form.Item>
        <Button
          id={submitButtonId}
          block
          loading={isLoading}
          type="primary"
          htmlType="submit"
        >
          {submitButtonText}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default SMSVerifyForm;
