// Import presets
import { preset } from 'styles';

// Prepare stylesheet
const styles = {
  dashboardWrapper: preset.content,
  dashboardCard: preset.card,
  dashboardCardBody: preset.cardBody,
  cardExtraButton: { padding: 0 },
  productCategory: { marginBottom: '30px' },
  titleDivider: { marginTop: '8px' },
};

export default styles;
