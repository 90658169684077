/** @jsxImportSource @emotion/react */
import { useState } from 'react';
import firebase from 'firebase/app';

// import Ant design
import {
    Form, Input, Button, message,
} from 'antd';

const ChangePasswordForm = () => {
    const [isConfirmPassowrdReadyToCheck, setIsConfirmPassowrdReadyToCheck] = useState(false);
    const [isUpdatingPassword, setIsUpdatingPassword] = useState(false);

    const [form] = Form.useForm();

    const handleConfirmBlur = e => {
        const { value } = e.target;
        setIsConfirmPassowrdReadyToCheck(isConfirmPassowrdReadyToCheck || !!value);
    };

    const compareToCurrentPassword = (rule, value, callback) => {
        if (value && value === form.getFieldValue('currentPassword')) {
            callback('New password must be different to current password.');
        } else {
            callback();
        }
    };

    const validateToNextPassword = (rule, value, callback) => {
        if (value && isConfirmPassowrdReadyToCheck) {
            form.validateFields(['confirmPassword'], { force: true });
        }

        callback();
    };

    const compareToFirstPassword = (rule, value, callback) => {
        if (value && value !== form.getFieldValue('newPassword')) {
            callback('Passwords do not match.');
        } else {
            callback();
        }
    };

    const updatePassword = values => {
        const { currentPassword, newPassword } = values;

        setIsUpdatingPassword(true);

        const updatePasswordReq = async () => {
            const { currentUser } = await firebase.auth();

            try {
                await currentUser.updatePassword(newPassword);
                setIsUpdatingPassword(false);
                message.success('Password Updated');
            } catch (error) {
                if (error.code === 'auth/requires-recent-login') {
                    // If the user's last sign-in time does not meet the security threshold, reauth
                    try {
                        const credential = firebase.auth.EmailAuthProvider.credential(
                            currentUser.email,
                            currentPassword,
                        );

                        await currentUser.reauthenticateWithCredential(credential);

                        updatePasswordReq();
                    } catch (reAuthError) {
                        setIsUpdatingPassword(false);
                        message.error(reAuthError.message);
                    }
                } else {
                    setIsUpdatingPassword(false);
                    message.error(error.message);
                }
            }
        };

        updatePasswordReq();
    };

    return (
        <Form
            layout="vertical"
            form={form}
            onFinish={updatePassword}
        >
            <Form.Item
                name="currentPassword"
                label="Current Password"
                rules={[{ required: true, message: 'Current password is required.' }]}
            >
                <Input.Password />
            </Form.Item>
            <Form.Item
                name="newPassword"
                label="New Password"
                hasFeedback
                extra="New password must be at least 8 characters and contain a lowercase letter, an uppercase letter, and a number."
                rules={[
                    { required: true, message: 'New password is required' },
                    { min: 8, message: 'Password must be between 8 and 64 characters long.' },
                    { max: 64, message: 'Password must be between 8 and 64 characters long.' },
                    { pattern: new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])'), message: 'Password does not fulfil requirements.' },
                    { validator: compareToCurrentPassword },
                    { validator: validateToNextPassword },
                ]}
            >
                <Input.Password />
            </Form.Item>
            <Form.Item
                name="confirmPassword"
                label="Confirm Password"
                hasFeedback
                rules={[
                    { required: true, message: 'Please confirm your password.' },
                    { validator: compareToFirstPassword },
                ]}
            >
                <Input.Password onBlur={handleConfirmBlur} />
            </Form.Item>
            <Form.Item>
                <Button
                    type="primary"
                    htmlType="submit"
                    loading={isUpdatingPassword}
                >
                    Update Password
                </Button>
            </Form.Item>
        </Form>
    );
};

export default ChangePasswordForm;
