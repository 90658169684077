/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { PageHeader, Form, Input, Space, Button, message, Card } from 'antd';
import { UploadFile } from 'antd/es/upload/interface';
import { Organisation } from '@eliiza-thea/auth-wrapper';

import { Container, ImageUploadFormItem, TimezoneSelect } from 'components';
import { createOrganisation, updateOrg, getAPIErrMsg } from 'api';
import { uploadOrgLogo } from 'storage';
import { preset } from 'styles';
import { FirebaseStorageError } from 'types';

export const CreateOrganisation: React.FC = () => {
  const [isCreating, setIsCreating] = useState<boolean>(false);

  const history = useHistory();

  const [form] = Form.useForm();

  const onCreateOrganisation = async (values: {
    name: string;
    logoFile?: UploadFile[];
    timezone?: string;
  }) => {
    const { name, logoFile, timezone } = values;
    const formattedOrg: Partial<Organisation> = { name: name.trim(), timezone };
    setIsCreating(true);
    try {
      // Create Org
      const newOrgSlug = await createOrganisation(formattedOrg);
      // Upload Org Logo
      let newLogoPath: string | null = null;
      if (Array.isArray(logoFile) && logoFile.length > 0) {
        const imageFile = logoFile[0];
        if ('originFileObj' in imageFile && imageFile.originFileObj) {
          try {
            newLogoPath = await uploadOrgLogo(
              imageFile.originFileObj,
              newOrgSlug
            );
          } catch (error) {
            message.error(
              (error as FirebaseStorageError)?.message || 'Upload Fail'
            );
          }
        } else if (imageFile.url) {
          newLogoPath = imageFile.url;
        }
      }
      // Update Org Logo
      if (newLogoPath) {
        try {
          await updateOrg(newOrgSlug, { logo: newLogoPath });
        } catch (error) {
          message.error(getAPIErrMsg(error));
        }
      }
      message.success('Organisation Created');
      history.push(`/superadmin/organisations/${newOrgSlug}`);
    } catch (error) {
      setIsCreating(false);
      message.error(getAPIErrMsg(error));
    }
  };

  return (
    <div>
      <PageHeader
        ghost={false}
        title="Create Organisation"
        onBack={() => history.push('/superadmin/organisations')}
      />

      <Container paddingY={3}>
        <Card bordered={false}>
          <Form
            layout="vertical"
            form={form}
            onFinish={onCreateOrganisation}
            css={{
              maxWidth: preset.centerFormWidth,
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          >
            <Form.Item
              label="Organisation name"
              name="name"
              rules={[
                {
                  required: true,
                  message: 'Please input a name',
                  whitespace: true,
                },
              ]}
            >
              <Input />
            </Form.Item>

            <ImageUploadFormItem label="Logo" name="logoFile" />

            <Form.Item
              name="timezone"
              label="Time zone"
              tooltip="New users created in this organisation will have this time zone"
            >
              <TimezoneSelect />
            </Form.Item>

            <Form.Item>
              <Space size="middle">
                <Button type="primary" htmlType="submit" loading={isCreating}>
                  Create
                </Button>
                <Button>
                  <Link to="/superadmin/organisations">Cancel</Link>
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </Card>
      </Container>
    </div>
  );
};
