/** @jsxImportSource @emotion/react */
import { useAuth } from '@eliiza-thea/auth-wrapper';

import { Container, OrgUsersTable } from 'components';

export const Users = () => {
  const { currentOrg } = useAuth();
  return (
    <Container paddingY={3}>
      <OrgUsersTable orgSlug={currentOrg?.id} />
    </Container>
  );
};
