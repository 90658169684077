/** @jsxImportSource @emotion/react */
import React from 'react';
import { Form, Upload, Space, Typography, Button, Image } from 'antd';
import { UploadFile } from 'antd/es/upload/interface';
import { FileImageOutlined } from '@ant-design/icons';

import { colors, preset } from 'styles';

type Props = { name: string; label?: string; className?: string };

type URLFile = { url: string };

export const ImageUploadFormItem: React.FC<Props> = ({
  name,
  label,
  className,
}) => {
  return (
    <Form.Item
      noStyle
      shouldUpdate={(prevValues, currentValues) =>
        prevValues[name] !== currentValues[name]
      }
    >
      {({ getFieldValue, setFieldsValue }) => {
        const fileList: UploadFile[] | URLFile[] = getFieldValue(name)
          ? Array.isArray(getFieldValue(name))
            ? getFieldValue(name)
            : []
          : [];

        let previewImgURL: string | null = null;
        let clearObjectURLCache = false;

        if (fileList.length > 0) {
          try {
            const file = fileList[0];
            if ('originFileObj' in file) {
              clearObjectURLCache = true;
              previewImgURL = URL.createObjectURL(file.originFileObj);
            } else if (file.url) {
              previewImgURL = file.url;
            }
          } catch {
            previewImgURL = null;
          }
        }

        return (
          <Form.Item
            label={label}
            name={name}
            valuePropName="fileList"
            className={className}
            getValueFromEvent={(e) => (Array.isArray(e) ? e : e && e.fileList)}
            css={{
              '.ant-form-item-explain-error': {
                marginBottom: preset.spacing(2),
              },
            }}
          >
            <Upload
              maxCount={1}
              accept="image/jpeg, image/png"
              showUploadList={false}
              openFileDialogOnClick={fileList.length === 0}
              beforeUpload={() => false}
            >
              <Space size="large">
                <div
                  css={{
                    width: preset.spacing(10),
                    height: preset.spacing(10),
                    cursor: fileList.length > 0 ? 'auto' : 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    border: previewImgURL
                      ? 'none'
                      : `dashed 1px ${colors.gray}`,
                  }}
                >
                  {previewImgURL ? (
                    <Image
                      preview={false}
                      src={previewImgURL}
                      onLoad={() => {
                        if (clearObjectURLCache && previewImgURL) {
                          URL.revokeObjectURL(previewImgURL);
                        }
                      }}
                    />
                  ) : (
                    <FileImageOutlined
                      css={{ color: colors.gray, fontSize: 24 }}
                    />
                  )}
                </div>
                <div>
                  <Typography.Paragraph type="secondary">
                    JPG, JPEG or PNG
                  </Typography.Paragraph>
                  {fileList.length > 0 ? (
                    <Button
                      size="middle"
                      onClick={() => setFieldsValue({ [name]: [] })}
                    >
                      Remove
                    </Button>
                  ) : (
                    <Button size="middle">Upload</Button>
                  )}
                </div>
              </Space>
            </Upload>
          </Form.Item>
        );
      }}
    </Form.Item>
  );
};
